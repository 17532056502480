@import 'bootstrap/dist/css/bootstrap.min.css';

/* Zentriert den Spinner */
.custom-spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh;
  }
  
  /* Farbe des Spinners */
  .custom-spinner .spinner-border {
    color: #00695C; 
  }
  